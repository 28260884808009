import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Observable, catchError, map } from 'rxjs';
import { Response } from '@parashift/ngx-airbrush';
import { Blob } from 'activestorage';
import { ListFilter, Me, UrlVars } from '@parashift/shared/models';
import { ServiceDecorator } from './decorators';
import { BaseApiService } from './base';
import { SessionService } from '../session.service';
import { QueryParamsService } from '../query-params.service';
import { AUTHORIZATION, AuthorizationMethod } from '@parashift/shared/constants';
import { Endpoint } from '../env.service';

export interface QrCodeGenerationResult {
  data: { qrcode: string; };
  success: boolean;
}

@Injectable({
  providedIn: 'root'
})
@ServiceDecorator({
  model: () => Me,
  endpointUrl: 'me'
})
export class MeService extends BaseApiService<Me> {
  className = MeService;

  constructor(
    http: HttpClient,
    queryParamsService: QueryParamsService,
    private sessionService: SessionService
  ) {
    super(http, queryParamsService);
    this.baseUrl = Endpoint.id;
    this.apiVersion = this.environment.endpoints.version
  }

  getInitialUser(listFilter: ListFilter): Observable<Me> {
    const params = this.queryParamsService.getParams(listFilter, true, true);
    const urlVars = { after_endpoint_url: params ? '?' + params : '' } as UrlVars;
    this.setProperties(undefined, urlVars);
    const url = this.buildUrl();
    const context = new HttpContext().set(AUTHORIZATION, { method: AuthorizationMethod.global_token, token: this.sessionService.global_token })
    const httpCall = this.http.get(url, { context, observe: 'response' }) as Observable<Response>;

    return httpCall.pipe(
      map((res: Response) => this.successful(res)),
      map((res: Response) => this.extract(res) as Me),
      catchError((res: any) => this.handleError(res))
    );
  }

  saveRecord(model: Me, tenant_id?: number, urlVars?: UrlVars): Observable<Me> {
    this.setProperties(tenant_id, urlVars);
    const url = this.buildUrl();
    const body = model.toJsonapi();
    const httpCall = this.http.put<object>(url, body, { observe: 'response' }) as Observable<Response>;

    return httpCall.pipe(
      map((res: Response) => this.successful(res)),
      map((res: Response) => this.extract(res) as Me),
      catchError((res: any) => this.handleError(res))
    );
  }

  generateTwoFactorAuthenticationCode(): Observable<string> {
    const url = this.buildUrl();

    return this.http.post<QrCodeGenerationResult>(url + '/generate-2fa/', {})
      .pipe(
        map(response => response.data.qrcode)
      );
  }

  enableTwoFactorAuthentication(otpCode: string) {
    const url = this.buildUrl();
    const body = {
      data: {
        otp_code: otpCode
      }
    };

    return this.http.post(url + '/enable-2fa/', JSON.stringify(body));
  }

  addAvatarFromDirectUpload(fileHandler: Blob) {
    const url = this.buildUrl();
    return this.http.post(url + '/avatar', {
      blob_signed_id: fileHandler.signed_id
    });
  }
}
